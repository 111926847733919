<template>
    <div class="product-home">
        <header class="product-header">
            <p class="product-subtitle" :style="{ color: colors.primary }">人脸鼠标 (face mouse)</p>
            <p class="product-slogan" :style="{ color: colors.secondary }">让科技改变生活。</p>
        </header>
        <div class="product-description">
            <div class="product-video">
                <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=113281977031364&bvid=BV1Mp2GYzEfN&cid=26226067199&p=1" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
            </div>
            <p class="product-info">如果您或者您的家人朋友因身体不适无法操作常规鼠标，请使用或推荐他们使用人脸鼠标，它将带来全新的操作体验。</p>
        </div>
        <div class="product-list">
            <div class="product-item" v-for="feature in features" :key="feature.id">
                <h2 class="feature-title">{{ feature.title }}</h2>
                <p class="feature-description">{{ feature.description }}</p>
            </div>
        </div>
        <footer>
            <p>© 2024 by justverman | <a href="mailto:justverman@gmail.com">联系我们</a></p>
        </footer>
    </div>
</template>

<script>
import { colors } from '../colors.js'; // 引入配置文件

export default {
    data() {
        return {
            features: [
                { id: 1, title: '绝对隐私', description: '它是独立的单机设备，在您使用后不会上传任何数据到云端。' },
                { id: 2, title: '绝对控制', description: '它提供对设备鼠标的点击和移动，较好的适应多数应用场景。' },
                { id: 3, title: '绝对免费', description: '它在下载和使用上完全免费，您和您的家人朋友可以免费使用。' },
            ],
            colors,
        };
    },
};
</script>

<style scoped>
.product-home {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9; /* 使用柔和的背景色 */
    font-family: 'Georgia', serif; /* 使用优雅的字体 */
    width: 100%;
}

.product-header {
    margin-top: 80px; /* 增加导航栏与标题之间的距离 */
}

.product-subtitle {
    font-size: 3.5em; /* 增加字体大小 */
    font-weight: 700; /* 加粗字体 */
    margin-bottom: 10px; /* 调整标题与副标题之间的间距 */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.product-slogan {
    font-size: 2em; /* 增加字体大小 */
    font-weight: 400; /* 正常字体 */
    margin-bottom: 20px; /* 调整与其他元素的间距 */
    font-style: italic; /* 斜体效果 */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
    text-align: left; /* 左对齐 */
    margin-left: 60%; /* 向右偏移 */
}

.product-description {
    margin: 20px 0;
}

.product-video {
    width: 70%; /* 视频宽度为80% */
    height: 900px; /* 设置视频高度 */
    margin: 0 auto; /* 居中对齐 */
}

.product-video iframe {
    width: 100%; /* iframe 宽度为100% */
    height: 100%; /* iframe 高度为100% */
    border-radius: 15px; /* 圆角效果 */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2); /* 更强的阴影效果 */
}

.product-info {
    font-size: 1.5em; /* 增加描述文字大小 */
    color: #34495e; /* 深色描述文字 */
    margin-top: 15px; /* 描述文字与视频之间的间距 */
}

.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px 0; /* 增加上下间距 */
}

.product-item {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    margin: 15px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: #ffffff; /* 白色背景 */
}

.product-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* 悬停时更强的阴影效果 */
}

.feature-title {
    font-size: 1.8em; /* 增加功能标题大小 */
    margin-bottom: 5px; /* 调整功能标题与描述之间的间距 */
    color: #e91e63; /* 使用鲜艳的颜色 */
}

.feature-description {
    font-size: 1em; /* 根据需要调整功能描述大小 */
    color: #555; /* 功能描述颜色 */
}

footer {
    margin-top: 40px;
    font-size: 14px;
    color: #777;
}

footer a {
    color: #e91e63;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}
</style>
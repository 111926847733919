<template>
    <div id="app">
        <header class="app-header">
            <nav>
                <ul>
                    <li><a href="#home">主页</a></li>
                    <li><a href="https://wf757j.aliwork.com/o/smilemouse">反馈</a></li>
                    <li><a href="#contact" @click.prevent="showDonationImage">捐赠</a></li>
                </ul>
            </nav>
            <button class="download-button" @click="downloadFile">下载</button>
        </header>
        <ProductHome />
        
        <!-- 模态框 -->
        <div v-if="isModalVisible" class="modal" @click="closeModal">
            <img src="./assets/zhifub.jpg" alt="捐赠" class="modal-image" />
        </div>
    </div>
</template>

<script>
import ProductHome from './components/productHome.vue';
import { colors } from './colors.js'; // 引入配置文件

export default {
    components: {
        ProductHome,
    },
    data() {
        return {
            isModalVisible: false, // 控制模态框的显示
        };
    },
    mounted() {
        // 设置 CSS 变量
        document.documentElement.style.setProperty('--primary-color', colors.primary);
        document.documentElement.style.setProperty('--secondary-color', colors.secondary);
    },
    methods: {
        downloadFile() {
            const link = document.createElement('a');
            link.href = 'https://facemouse.justverman.com/package/FaceMouseInstaller.exe'; // 确保文件路径正确
            link.download = 'FaceMouseInstaller.exe'; // 设置下载文件名
            document.body.appendChild(link);
            link.click(); // 触发点击事件
            document.body.removeChild(link); // 移除链接元素
        },
        showDonationImage() {
            this.isModalVisible = true; // 显示模态框
        },
        closeModal() {
            this.isModalVisible = false; // 隐藏模态框
        },
    },
};
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* 确保所有元素的宽度和高度包括内边距和边框 */
}

#app {
    font-family: 'Arial', sans-serif;
    text-align: center;
    color: #333;
    background-color: #f4f4f4;
    width: 100%;
    overflow-x: hidden; /* 防止出现水平滚动条 */
}

.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 使导航项和下载按钮分开对齐 */
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.9); /* 设置为白色透明背景 */
    color: #333;
    position: absolute; /* 使导航栏覆盖在图片上 */
    top: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加轻微阴影以增强可读性 */
}

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 0;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: var(--secondary-color); /* 使用辅助色 */
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1em; /* 增加链接字体大小 */
}

nav ul li a:hover {
    text-decoration: underline;
}

.download-button {
    background-color: var(--primary-color); /* 使用主体色 */
    color: white; /* 设置文字颜色为白色 */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.1em; /* 增加按钮字体大小 */
}

.download-button:hover {
    background-color: #45a049; /* 悬停时稍微变深的绿色 */
}

/* 模态框样式 */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 确保模态框在最上层 */
}

.modal-image {
    max-width: 80%; /* 限制图片宽度 */
    max-height: 80%; /* 限制图片高度 */
}
</style>